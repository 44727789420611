import {useThread} from "@/Store/Thread.js";
import {storeToRefs} from "pinia";
import {nextTick} from "vue";
import route from "ziggy-js";
import {router, usePage} from "@inertiajs/vue3";
import debounce from "lodash/debounce.js";

export default {
    install: (app, options) => {
        const threadStore = useThread()
        const {messageChannel, thread, messages, threads, dialogElement, hasNewMessage, orders} = storeToRefs(threadStore)

        const reload = () => {
            router.reload({
                only: ['threads', 'orders'],
                onSuccess: () => {
                    threads.value = usePage().props.threads.data
                    orders.value = usePage().props.orders.data
                }
            })
        }

        const fetchDebounced = debounce(reload, 10000)

        const updateMessageChannel = user => {
            if (user) {
                if (messageChannel.value == null) {
                    messageChannel.value = Echo.private('App.Models.User.' + user.id.toString())
                    messageChannel.value.listen('.MessageCreated', (e) => {
                        if (e.model.user_id !== user.id && e.model.thread_id !== thread.value?.id) {
                            hasNewMessage.value = true
                        }

                        if (e.model.thread_id === thread.value?.id) {
                            messages.value[e.model.id] = e.model
                            if (e.model.offer) {
                                thread.value.user.offer = e.model.offer
                            }

                            nextTick().then(() => {
                                dialogElement.value.scrollBy(0, dialogElement.value.scrollHeight)
                            })
                        }
                        if (route().current('threads.*')) {
                            reload()
                        }
                        if (route().current('admin.threads.*')) {
                            fetchDebounced()
                        }
                    })
                }
            } else {
                if (messageChannel.value) {
                    messageChannel.value.stopListening('.MessageCreated')
                    messageChannel.value = null
                }
            }
        };



        app.provide('updateMessageChannel', updateMessageChannel);

        updateMessageChannel(options?.user)
    }
}
